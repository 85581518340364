<template lang="pug">
  v-layout#classified-card(justify-start fill-height align-space-between)
    v-layout(column)
      v-flex.loading-box(v-if="item !== null")
        v-badge.base-name(color="grey" overlap)
          span(slot="badge" small v-if="hasMultiLoading") {{ multiLoadingCount }}
          v-chip.text-truncate.base-name-label(label)
            span.area-highlight.area-color-box(:class="getAreaColorClass(item.loadingSpecifiedTimeType)")
            span {{ item.firstLoadingBaseName }}
            span.d-none {{ item.cardId }}
          TrackingCardIcon.tracking-icon-box(:arrivalStatus="item.loadingArrivalStatus")
          div.icon-lift-specified-position
            LiftTypeIcon(:liftType="item.loadingRequiredLiftType" :displayTypeNone="false")
      v-flex.arrow-icon-box(v-if="item !== null")
        v-icon(small) arrow_drop_down
      v-flex.un-loading-box(v-if="item !== null")
        v-badge.base-name(color="grey" overlap)
          span(slot="badge" small v-if="hasMultiUnLoading") {{ multiUnLoadingCount }}
          v-chip.text-truncate.base-name-label(label)
            span.area-highlight.area-color-box(:class="getAreaColorClass(item.unloadingSpecifiedTimeType)")
            span {{ item.firstUnloadingBaseName }}
          TrackingCardIcon.tracking-icon-box(:arrivalStatus="item.unloadingArrivalStatus")
          div.icon-time-specified
            SpecifiedTimeTypeIcon(v-if="isSpecifiedTime" :specifiedTimeType="item.unloadingSpecifiedTimeType")
          div.icon-lift-specified-position
            LiftTypeIcon(:liftType="item.unloadingRequiredLiftType" :displayTypeNone="false")
    v-layout.icons.text-xs-right(align-center justify-end column fill-height shrink v-if="item !== null")
      v-layout.icon-row(row v-if="item.isRelay")
        v-btn.icon-relay-specified(icon)
          RerayIcon
      v-layout.icon-row(row v-if="isNightLoadingDest")
        v-btn(icon small flat disabled)
          v-icon.night-loading-icon-size far fa-moon
      v-layout.icon-row(row v-if="isNightLoading || isNightLoadingAndDayExceed")
        v-btn(icon small flat disabled)
          v-icon.night-loading-icon-size fas fa-moon
      v-layout.icon-row(row v-if="isDayExceedDest")
        v-btn(icon small flat disabled)
          v-icon() brightness_7
      v-layout.icon-row(row v-if="!item.isRelay && !(isNightLoading || isNightLoadingDest || isNightLoadingAndDayExceed) && !isDayExceedDest")
        div &nbsp;
      v-layout.icon-row(row)
        v-btn(icon flat color="grey" small slot="activator" @click="toggleMarkMenu").small-btn
          ClassifiedCardMarkIcon(:markType="item.cardMark")
      v-layout.icon-row(row)
        v-btn(icon flat color="grey" small slot="activator" @click="toggleRemarkEditDialog")
          RemarkStatusIcon(:hasRemark="item.hasRemark" :hasSlideRemark="item.hasSlideRemark")
      v-layout.icon-row(row)
        v-btn(icon flat color="grey" small slot="activator" @click="toggleCardContextMenu"
          @mouseenter="triggerOpenPopUpCard" @mouseleave="triggerClosePopUpCard")
          v-icon() more_vert
</template>

<script>
import TrackingCardIcon from "@/components/dynamic/TrackingCardIcon";
import LiftTypeIcon from "@/components/dispatch/LiftTypeIcon";
import SpecifiedTimeTypeIcon from "@/components/common/SpecifiedTimeTypeIcon";
import RemarkStatusIcon from "@/components/dispatch/RemarkStatusIcon";
import CardWarningIcon from "@/components/dispatch/CardWarningIcon";
import RerayIcon from "@/components/dispatch/RerayIcon";
import BaseNameDialog from "@/components/dispatch/OpenBaseNameDialog";
import ClassifiedCardMarkIcon from "@/components/dispatch/ClassifiedCardMarkIcon";

import * as Enum from "@/assets/js/enum";

export default {
  components: {
    TrackingCardIcon,
    LiftTypeIcon,
    SpecifiedTimeTypeIcon,
    RemarkStatusIcon,
    CardWarningIcon,
    RerayIcon,
    BaseNameDialog,
    ClassifiedCardMarkIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loadingBaseMenu: false,
      unloadingBaseMenu: false
    };
  },
  computed: {
    hasMultiLoading() {
      return this.item.loadingBaseCount > 1;
    },
    hasMultiUnLoading() {
      return this.item.unloadingBaseCount > 1;
    },
    multiLoadingCount() {
      return this.hasMultiLoading ? this.item.loadingBaseCount : 0;
    },
    multiUnLoadingCount() {
      return this.hasMultiUnLoading ? this.item.unloadingBaseCount : 0;
    },
    isSpecifiedTime() {
      return this.item.unloadingSpecifiedTimeType !== "00";
    },
    isNightLoading() {
      return this.item.dispatchType === Enum.DispatchType.NightLoading.code;
    },
    isNightLoadingDest() {
      return this.item.dispatchType === Enum.DispatchType.NightLoadingDest.code;
    },
    isNightLoadingAndDayExceed() {
      return this.item.dispatchType === Enum.DispatchType.NightLoadingAndDayExceed.code;
    },
    isDayExceedDest() {
      return this.item.dispatchType === Enum.DispatchType.DayExceedDest.code;
    }
  },
  methods: {
    /**
     * 備考ダイアログを表示
     *
     * @param event
     */
    toggleRemarkEditDialog(event) {
      event.preventDefault();
      this.$emit("openRemarkEditDialog", {
        item: this.item,
        event: event
      });
    },
    /**
     * カード用のコンテキストメニューを表示
     * @param event
     */
    toggleCardContextMenu(event) {
      event.preventDefault();
      this.$emit("openCardContextMenu", {
        item: this.item,
        event: event
      });
    },
    /**
     * マーク用メニューを開く
     * @param event
     */
    toggleMarkMenu(event) {
      this.$nextTick(() => {});
      this.$emit("openMarkMenu", {
        item: this.item,
        event: event
      });
    },
    getAreaColorClass(id) {
      return "area-color-" + id;
    },
    triggerOpenPopUpCard(event) {
      event.preventDefault();
      this.$emit("triggerOpenPopUpCard", {
        item: this.item,
        event: event
      });
    },
    triggerClosePopUpCard(event) {
      event.preventDefault();
      this.$emit("triggerClosePopUpCard", {
        item: this.item,
        event: event
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.tracking-icon-box {
  position: absolute;
  top: -8px;
  left: -6px;
  width: 100px;
  height: 24px;
}

.night-loading-icon-size {
  font-size: 20px;
}

.icons{
  $iconSize = 22px
  .v-btn{
    width $iconSize  !important
    height $iconSize !important
  }

  i{
    font-size $iconSize !important
  }
}
</style>

<template lang="pug">
  v-layout#classified-card(justify-start column fill-height align-space-between).card-box
    CardWarningIcon(:card="item" v-if="displayWarning")
    div.vehicle-type-box(v-if="item.requiredVehicleTypeCd && displayRequiredVehicleTypeIcon")
      VehicleTypeChipLabel(:label="item.requiredVehicleType" :code="item.requiredVehicleTypeCd")
    v-flex.loading-box(v-show="hasData")
      v-badge.base-name(color="grey" overlap)
        span(slot="badge" small v-if="multiLoadingCount>1") {{ multiLoadingCount }}
        v-chip.text-truncate.base-name-label(label)
          span.area-highlight.area-color-box(:class="getAreaColorClass(item.loadingSpecifiedTimeType)")
          span {{ item.firstLoadingBaseName }}
          span.d-none {{ item.cardId }}
        div.icon-lift-specified-position
          LiftTypeIcon(:liftType="item.loadingRequiredLiftType" :displayTypeNone="false")

    v-flex.arrow-icon-box(v-show="hasData")
      v-icon(small) arrow_drop_down

    v-flex.un-loading-box(v-show="hasData")
      v-badge.base-name(color="grey" overlap)
        span(slot="badge" small v-if="multiUnLoadingCount>1") {{ multiUnLoadingCount }}
        v-chip.text-truncate.base-name-label(label)
          span.area-highlight.area-color-box(:class="getAreaColorClass(item.unloadingSpecifiedTimeType)")
          span {{ item.firstUnloadingBaseName }}
        div.icon-time-specified
          SpecifiedTimeTypeIcon(v-if="isSpecifiedTime" :specifiedTimeType="item.unloadingSpecifiedTimeType")
        div.icon-lift-specified-position
          LiftTypeIcon(:liftType="item.unloadingRequiredLiftType" :displayTypeNone="false")

    v-flex.icons(v-show="hasData")
      v-layout(align-center justify-end row fill-height)
        v-flex(align-center fill-height).text-xs-right
          div.icon-relay-specified(v-if="item.isRelay")
            RerayIcon
          v-btn(icon small flat disabled v-if="isNightLoadingDest")
            v-icon.night-loading-icon-size far fa-moon
          v-btn(icon small flat disabled v-if="isNightLoading || isNightLoadingAndDayExceed")
            v-icon.night-loading-icon-size fas fa-moon
          v-btn(icon small flat disabled v-if="isDayExceedDest")
            v-icon brightness_7
          v-btn(icon flat color="grey" small slot="activator" @click="toggleRemarkEditDialog" v-if="isDisplayRemarkIcon")
            RemarkStatusIcon(:hasRemark="item.hasRemark" :hasSlideRemark="item.hasSlideRemark")
          v-btn(icon flat color="grey" small slot="activator" @click="toggleMarkMenu" v-if="isDisplayMarkIcon")
            ClassifiedCardMarkIcon(:markType="item.cardMark")
          v-btn(icon flat color="grey" small @click="toggleCardContextMenu"
          @mouseenter="triggerOpenPopUpCard" @mouseleave="triggerClosePopUpCard" v-show="!readOnly")
            v-icon() more_vert
          v-btn(icon flat color="grey" small @click="openCardDetailAuto" v-show="readOnly" )
            v-icon info

    v-flex.icons(v-show="!hasData && !isRelayRow")
      v-layout(align-end justify-end row fill-height)
        v-flex.text-xs-right
          v-btn(icon flat color="grey" small slot="activator" @click="toggleCardContextMenu")
            v-icon() more_vert

</template>

<script>
import SpecifiedTimeTypeIcon from "@/components/common/SpecifiedTimeTypeIcon";
import RemarkStatusIcon from "@/components/dispatch/RemarkStatusIcon";
import ClassifiedCardMarkIcon from "@/components/dispatch/ClassifiedCardMarkIcon";
import CardWarningIcon from "@/components/dispatch/CardWarningIcon";
import LiftTypeIcon from "@/components/dispatch/LiftTypeIcon";
import RerayIcon from "@/components/dispatch/RerayIcon";

import * as Enum from "@/assets/js/enum";
import VehicleTypeChipLabel from "@/components/common/VehicleTypeChipLabel.vue";

export default {
  components: {
    VehicleTypeChipLabel,
    SpecifiedTimeTypeIcon,
    RemarkStatusIcon,
    ClassifiedCardMarkIcon,
    CardWarningIcon,
    LiftTypeIcon,
    RerayIcon
  },
  props: {
    driverId: {
      type: Number,
      required: false,
      default: null
    },
    itemKey: {
      type: Number,
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: true
    },
    isRelayRow: {
      type: Boolean,
      required: false,
      default: () => false
    },
    isRecentlyReturned: {
      type: Boolean,
      required: false,
      default: () => false
    },
    displayWarning: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hiddenIconMark: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hiddenIconRemark: {
      type: Boolean,
      required: false,
      default: () => false
    },
    readOnly: {
      type: Boolean,
      default: () => false
    },
    displayRequiredVehicleTypeIcon: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loadingBaseMenu: false,
      unloadingBaseMenu: false,
      cardDetailMenu: false
    };
  },
  computed: {
    hasData() {
      return this.item.isBlank === false;
    },
    hasMultiLoading() {
      return this.item.loadingBaseCount > 0;
    },
    hasMultiUnLoading() {
      return this.item.unloadingBaseCount > 0;
    },
    multiLoadingCount() {
      return this.hasMultiLoading ? this.item.loadingBaseCount : 0;
    },
    multiUnLoadingCount() {
      return this.hasMultiUnLoading ? this.item.unloadingBaseCount : 0;
    },
    isNightLoading() {
      return this.item.dispatchType === Enum.DispatchType.NightLoading.code;
    },
    isNightLoadingDest() {
      return this.item.dispatchType === Enum.DispatchType.NightLoadingDest.code;
    },
    isNightLoadingAndDayExceed() {
      return this.item.dispatchType === Enum.DispatchType.NightLoadingAndDayExceed.code;
    },
    isDayExceedDest() {
      return this.item.dispatchType === Enum.DispatchType.DayExceedDest.code;
    },
    isSpecifiedTime() {
      return this.item.unloadingSpecifiedTimeType !== Enum.SpecifiedTimeType.None.code;
    },
    isDisplayRemarkIcon() {
      return this.hiddenIconRemark !== true;
    },
    isDisplayMarkIcon() {
      return this.hiddenIconMark !== true;
    },
    isTempCard() {
      return this.item.orderType === Enum.OrderType.TempCard.code;
    }
  },
  methods: {
    /**
     * 備考ダイアログを表示
     *
     * @param event
     */
    toggleRemarkEditDialog(event) {
      event.preventDefault();
      this.$emit("openRemarkEditDialog", {
        item: this.item,
        event: event
      });
    },
    /**
     * カード用のコンテキストメニューを表示
     * @param event
     */
    toggleCardContextMenu(event) {
      event.preventDefault();
      this.$emit("openCardContextMenu", {
        itemKey: this.itemKey,
        item: this.item,
        driverId: this.driverId,
        isRelayRow: this.isRelayRow,
        isRecentlyReturned: this.isRecentlyReturned,
        event: event
      });
    },
    /**
     * マーク用メニューを開く
     * @param event
     */
    toggleMarkMenu(event) {
      this.$nextTick(() => {});
      this.$emit("openMarkMenu", {
        item: this.item,
        event: event
      });
    },
    getAreaColorClass(id) {
      return "area-color-" + id;
    },
    triggerOpenPopUpCard(event) {
      event.preventDefault();
      this.$emit("triggerOpenPopUpCard", {
        item: this.item,
        event: event
      });
    },
    triggerClosePopUpCard(event) {
      event.preventDefault();
      this.$emit("triggerClosePopUpCard", {
        item: this.item,
        event: event
      });
    },
    openCardDetailAuto(event) {
      if (this.isTempCard) {
        this.openWildCardEditDialog(event);
      } else {
        this.openCardDetailDialog(event);
      }
    },
    openCardDetailDialog(event) {
      event.preventDefault();
      this.$emit("openCardDetailDialog", this.item);
    },
    openWildCardEditDialog(event) {
      this.$emit("openWildCardEditDialog", { item: this.item, driverId: this.driverId });
    }
  }
};
</script>

<style lang="stylus" scoped>
.night-loading-icon-size {
  font-size: 20px;
}

.card-box,.un-loading-box,.loading-box {
  position: relative;
}

.vehicle-type-box {
  position: absolute;
  width: 24px;
  height: 24px;
  text-align: center;
  top: -15px;
  right: 12px;
  z-index: 100;

  /*>>> .one-char-chip > span{*/
  //  margin-left: 4px;
  //}
}
</style>
